import { connect } from 'react-redux';
import { ADDRESS_TYPE } from "constants";
import { selectedVariations } from 'actions/admin/order';
import { Button, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';
import { useEffect, useState } from 'react';

const ShippingLoggedIn = ({
    variationData,
    selectedVariations,
    addressList,
    addressValueData,
    title,
    handleCheckedChange,
    handleShippingAddressChange,
    handleBillingAddressChange,
    toggleAddressModal
}) => {
    const [shippingAddress, setShippingAddress] = useState([]);
    const [billingAddress, setBillingAddress] = useState([]);
    useEffect(() => {
        showSelectedValue()
    }, [variationData.selectedShippingAddress, variationData.selectedBillingAddress])
    const showSelectedValue = () => {
        if (variationData.orderId !== undefined) {
            if (title === "Shipping Address") {
                return setShippingAddress([{ label: variationData.selectedShippingLabel, value: variationData.selectedShippingAddress }]);
            } else {
                return setBillingAddress([{ label: variationData.selectedBillingLabel, value: variationData.selectedBillingAddress }]);
            }
        } else {
            if (title === "Shipping Address") {
                return setShippingAddress([{ label: variationData.selectedShippingLabel, value: variationData.selectedShippingAddress }]);
            } else {
                return setBillingAddress([{ label: variationData.selectedBillingLabel, value: variationData.selectedBillingAddress }]);
            }
        }
    }
    return (
        <div className={title === "Shipping Address" ? "address" : "address billingADrs"}>
            {title === "Shipping Address" && <><div className="couponcode mb-3 billing-heading">
                {/* <h5>Addresses</h5> */}
            </div>
                <div className="couponcode mb-0">
                    <Label for="payment_status">
                        <strong>{title}</strong>
                    </Label>
                </div></>}

            {title !== "Shipping Address" && <div className="mb-2 billing-heading"><b>{title}</b></div>}

            {addressList.length > 0 ? (
                <div className="">
                    {
                        <Select
                            isClearable={false}
                            value={title === "Shipping Address" ? shippingAddress : billingAddress}
                            id={title === "Shipping Address" ? "shipping_address" : "billing_address"}
                            name={title === "Shipping Address" ? "selected_sihppinhg_address" : "selected_address"}
                            options={addressValueData && addressValueData}
                            onChange={(e) => {
                                title === "Shipping Address" ? handleShippingAddressChange(e) : handleBillingAddressChange(e);
                            }}
                            keepSelectedInList={false}
                        />
                    }
                </div>
            ) : (
                'No Address'
            )}
            <FormGroup className="mt-3">
                <span>

                    {(title === "Shipping Address" && variationData?.shippingAddressData) && (
                        <strong>
                            <p>
                                {variationData?.shippingAddressData.name} ({variationData?.shippingAddressData.email}{' '}
                                {variationData?.shippingAddressData.ccode}
                                {variationData?.shippingAddressData.contact_no})
                            </p>
                            <p>{variationData?.shippingAddressData.address}</p>
                        </strong>
                    )}
                    {(title === "Billing Address" && variationData?.billingAddressData) && <strong>
                        <p>
                            {variationData?.billingAddressData.name} ({variationData?.billingAddressData.email}{' '}
                            {variationData?.billingAddressData.ccode}
                            {variationData?.billingAddressData.contact_no})
                        </p>
                        <p>{variationData?.billingAddressData.address}</p>
                    </strong>}
                </span>
            </FormGroup>
            <FormGroup>
                <Button
                    type={"button"}
                    className="btn-primary d-inline-flex"
                    onClick={(e) => toggleAddressModal(title === "Shipping Address" ? ADDRESS_TYPE.SHIPPING : ADDRESS_TYPE.BILLING)}
                >
                    Add Address
                </Button>
            </FormGroup>
            {title === "Shipping Address" && < FormGroup >
                <Label>
                    <Input
                        type="checkbox"
                        id="new_shipping_address"
                        name="new_shipping_address"
                        checked={variationData.shippToDifferentAddressChecked}
                        className="mr-2"
                        onChange={(e) => handleCheckedChange(e)}
                    />
                    Bill to different address
                </Label>
            </FormGroup>}
        </div >
    )
}

const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    selectedVariations
})(ShippingLoggedIn);