import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectedVariations } from 'actions/admin/order';
import ButtonSpinner from 'views/ButtonSpinner';
import {
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
} from 'reactstrap';
import Select from 'react-select';
import { ORDER_LIST_PAYMENT_STATUS, ORDER_LIST_PAYMENT_METHOD, PAYMENT_METHOD } from 'constants';
import { calculateDueAmount, calculatePaidAmount, amountDue, totalBeforeTax, productTotal } from './OrderCalculation';
import { useCallback, useMemo } from 'react';

const CalculationDetail = ({
  currentOrder,
  variationData,
  selectedVariations,
  handleOnChange,
  setDeliveryInstruction,
  couponDiscount,
  couponShippingDiscount,
  isApplied,
  couponCode,
  onChangeCouponCode,
  handleApplyCoupon,
  onRemoveClick,
  payment_status,
  productDetail,
  taxTotal,
  shippingPrice,
  grandTotal,
  dataSet,
  coupon_loading,
}) => {
  const statusList = () => {
    return Object.keys(ORDER_LIST_PAYMENT_STATUS).map((key) => {
      return { label: ORDER_LIST_PAYMENT_STATUS[key], value: key };
    });
  };

  const selectedStatus = () => {
    const status =
      payment_status !== undefined
        ? payment_status
        : currentOrder?.payment_status;
    return { label: ORDER_LIST_PAYMENT_STATUS[status], value: status };
  };
  const paymentType = useMemo(() => {
    return Object.keys(PAYMENT_METHOD).map((key) => {
      return { label: PAYMENT_METHOD[key]['value'], value: PAYMENT_METHOD[key]['key'] };
    });
  }, [PAYMENT_METHOD]);

  const paymentModeChange = useCallback((data) => {

    variationData = { ...variationData, payment_mode: data.value };
    selectedVariations(variationData);
  });

  const selectedPaymentType = useMemo(() => {
    return { label: ORDER_LIST_PAYMENT_METHOD[variationData.payment_mode], value: variationData.payment_mode };
  }, [variationData.payment_mode]);

  const otherPaymentMethod = useCallback((value) => {
    variationData = { ...variationData, payment_comment: value };
    selectedVariations(variationData);
  })

  return (
    <Card>
      <CardHeader>
        <h6>{"Payment Details"}</h6>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="6">
            <FormGroup>
              <div className="couponcode">
                <Label for="payment_status">
                  <strong>Payment status</strong>
                </Label>
              </div>
              <Select
                value={[selectedStatus()]}
                isClearable={false}
                name={'payment_status'}
                options={statusList()}
                onChange={(e) =>
                  handleOnChange(e, currentOrder?.payment_status)
                }
                placeholder={'Payment Status'}
                keepSelectedInList={false}
              />
            </FormGroup>
          </Col>
          {(payment_status === 2) && <>
            <Col md="6">
              <FormGroup>
                <div className="couponcode">
                  <Label for="payment_mode">
                    <strong>Payment Mode</strong>
                  </Label>
                </div>
                <Select
                  value={[selectedPaymentType]}
                  isDisabled={variationData.refundableAmount !== 0 ? true : false}
                  isClearable={false}
                  name={'payment_mode'}
                  options={paymentType}
                  noOptionsMessage={'Payment Mode'}
                  onChange={(e) =>
                    paymentModeChange(e)
                  }
                  placeholder={'Payment Mode'}
                  keepSelectedInList={false}
                />
              </FormGroup>
            </Col>
          </>}
          {(payment_status === 2) && <Col md="6">
            <FormGroup>
              <div className="couponcode">
                <Label for="payment_comment">
                  <strong>Payment Comments</strong>
                </Label>
              </div>
              <Input
                type="textarea"
                className="form-control text-left"
                id="payment_comment"
                name="payment_comment"
                placeholder="Write about the payment method"
                defaultValue={currentOrder?.payment_comment}
                onChange={(e) => otherPaymentMethod(e.target.value)}
              />
            </FormGroup>
          </Col>
          }
        </Row>

        {(currentOrder?.coupon != null &&
          currentOrder?.coupon &&
          Object.keys(currentOrder?.coupon).length > 0) ||
          couponDiscount !== 0 ||
          couponShippingDiscount !== 0 ||
          isApplied ? (
          <div className="mt-20">
            <div className="couponcode">
              <strong>Coupon Code </strong>
            </div>
            {couponDiscount || couponShippingDiscount || isApplied ? (
              <div className="apply-coupon">
                <strong className="coupon-display">{(isNaN(couponCode) ? couponCode : `$${couponCode}`) ?? ''}</strong>
                {coupon_loading ? <ButtonSpinner /> : <span
                  onClick={(e) => onRemoveClick(e)}
                  className="remove-coupon-span"
                >
                  <i className="fa fa-close"></i>
                </span>}
              </div>
            ) : (
              <Row>
                <Col>
                  <Input
                    id="coupon"
                    type="text"
                    name="coupon"
                    maxLength={50}
                    autoComplete="false"
                    placeholder="Coupon Code"
                    onChange={(e) => onChangeCouponCode(e)}
                    className="form-control text-left"
                    disabled={couponDiscount > 0 ? true : false}
                  />
                </Col>
                {couponDiscount <= 0 ? (
                  <Col className="apply-coupon">
                    {coupon_loading ? <ButtonSpinner /> : <Button
                      className="mr-05"
                      size="sm"
                      color="primary"
                      onClick={(e) => handleApplyCoupon(e)}
                    >
                      Apply
                    </Button>}
                  </Col>
                ) : null}
              </Row>
            )}
          </div>
        ) : (
          (variationData.selectedShippingAddress !== null && variationData?.shippingServiceCategory !== 3 && variationData.order_category === "normal_order") && (<div className="mt-20">
            <div className="couponcode">
              <strong>Coupon Code </strong>
            </div>

            <Row>
              <Col>
                <Input
                  id="coupon"
                  type="text"
                  name="coupon"
                  maxLength={50}
                  autoComplete="false"
                  placeholder="Coupon Code"
                  onChange={(e) => onChangeCouponCode(e)}
                  className="form-control text-left"
                  disabled={couponDiscount > 0 ? true : false}
                />
              </Col>
              {couponDiscount <= 0 ? (
                <Col className="apply-coupon">
                  {coupon_loading ? <ButtonSpinner /> : <Button
                    className="mr-05"
                    size="sm"
                    color="primary"
                    onClick={(e) => handleApplyCoupon(e)}
                  >
                    Apply
                  </Button>}
                </Col>
              ) : null}
            </Row>

          </div>)
        )}
        <div>
          {productDetail.addedProduct.length ? (
            <div className="mt-20">
              <Row className="couponcodelist priceSummary">
                <Col>
                  {/* {productDetail.productTotalAmount &&
                    productDetail.productTotalAmount > 0 ? ( */}
                  <div className="couponcode">
                    <strong>Product(s) Total : </strong>$
                    {Number(productDetail.productTotalAmount).toFixed(2)}
                  </div>
                  {/* ) : null} */}

                  {productDetail.fittingTotalAmount &&
                    productDetail.fittingTotalAmount > 0 ? (
                    <div className="couponcode">
                      <strong>Build Parameter(s) total :</strong> $
                      {Number(productDetail.fittingTotalAmount).toFixed(2)}
                    </div>
                  ) : null}
                  {(!couponCode || (isNaN(couponCode) || Number(couponDiscount) <= productTotal(Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0)))) && <div className="couponcode">
                    <strong>Discount : </strong> - ($
                    {Number(couponDiscount).toFixed(2)})
                  </div>}
                  {(taxTotal && taxTotal > 0) ? (
                    <>
                      <div className="couponcode">
                        <strong>Total before Tax :</strong> $
                        {totalBeforeTax(couponCode, Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0), Number(couponDiscount ?? 0))}
                      </div>
                      <div className="couponcode">
                        <strong>Sales Tax :</strong> $
                        {Number(taxTotal).toFixed(2)}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="couponcode">
                        <strong>Total before Tax :</strong> $
                        {totalBeforeTax(couponCode, Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0), Number(couponDiscount ?? 0))}
                      </div>
                      {((currentOrder?.tax_total !== undefined &&
                        Number(currentOrder?.tax_total) != 0) && taxTotal > 0) && (
                          <div className="couponcode">
                            <strong>Sales Tax :</strong> $
                            {Number(currentOrder?.tax_total).toFixed(2)}
                          </div>
                        )}
                    </>
                  )}
                  {variationData?.serviceTotalPrice > 0 && <div className="couponcode">
                    <strong>Service/Labour :</strong> $
                    {Number(variationData?.serviceTotalPrice).toFixed(2)}
                  </div>
                  }
                  {shippingPrice !== 0 ? (
                    <div className="couponcode">
                      <strong>Shipping :</strong> $
                      {Number(shippingPrice).toFixed(2)}
                    </div>
                  ) : (
                    currentOrder?.shipping_mode?.totalCharges !== undefined && (
                      <div className="couponcode">
                        <strong>Shipping :</strong> - ($
                        {Number(
                          currentOrder?.shipping_mode?.totalCharges
                        ).toFixed(2)}
                        )
                      </div>
                    )
                  )}
                  {couponShippingDiscount !== 0 && (
                    <div className="couponcode">
                      <strong>Shipping Discount :</strong> - ($
                      {Number(couponShippingDiscount).toFixed(2)})
                    </div>
                  )}
                  {shippingPrice !== 0 && couponShippingDiscount > 0 && (
                    <div className="couponcode">
                      <strong>Net Shipping Amount :</strong> $
                      {(
                        Number(shippingPrice) - Number(couponShippingDiscount)
                      ).toFixed(2)}
                    </div>
                  )}
                  {variationData.isEdit ? (
                    <>
                      {currentOrder?.paid_amount?.$numberDecimal >= 0 && (
                        <div className="couponcode">
                          <strong>Paid Amount : </strong>$
                          {calculatePaidAmount(currentOrder?.paid_amount?.$numberDecimal, 0)}
                        </div>
                      )}

                      {variationData.refundableAmount === 0 &&
                        amountDue(grandTotal, currentOrder?.paid_amount?.$numberDecimal, currentOrder?.refunded_amount) >= 0 && (
                          <div className="couponcode">
                            <strong>Due Amount : </strong>$
                            {amountDue(grandTotal, currentOrder?.paid_amount?.$numberDecimal, currentOrder?.refunded_amount)}
                          </div>
                        )}
                      {variationData.refundableAmount > 0 && (
                        <div className="couponcode">
                          <strong>Refundable Amount : </strong>$
                          {variationData.refundableAmount}
                        </div>
                      )}
                      {currentOrder?.refunded_amount > 0 && (
                        <div className="couponcode">
                          <strong>Refunded Amount : </strong> -($
                          {Number(currentOrder?.refunded_amount).toFixed(2)})
                        </div>
                      )}
                    </>
                  ) : null}
                  {(couponCode && (!isNaN(couponCode) && Number(couponDiscount) > productTotal(Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0)))) && <div className="couponcode">
                    <strong>Discount : </strong> - ($
                    {Number(couponDiscount).toFixed(2)})
                  </div>}
                  <div className="couponcode">
                    <strong>Grand Total : </strong>$
                    {Number(grandTotal).toFixed(2)}
                  </div>
                </Col>
              </Row>
            </div>
          ) : currentOrder !== undefined &&
            Object.keys(currentOrder).length > 0 ? (
            <div className="mt-20">
              <Row className="couponcodelist priceSummary">
                <Col>
                  {currentOrder?.product_total &&
                    currentOrder?.product_total > 0 ? (
                    <div className="couponcode">
                      <strong>Product(s) Total : </strong>$
                      {Number(currentOrder?.product_total).toFixed(2)}
                    </div>
                  ) : null}
                  {currentOrder?.discounted_amount.$numberDecimal &&
                    currentOrder?.discounted_amount.$numberDecimal != 0 && (
                      <div className="couponcode">
                        <strong>Discount : </strong> - ($
                        {Number(
                          currentOrder?.discounted_amount.$numberDecimal
                        ).toFixed(2)}
                        )
                      </div>
                    )}
                  {currentOrder?.fitting_total &&
                    currentOrder?.fitting_total > 0 ? (
                    <div className="couponcode">
                      <strong>Build Parameter(s) total :</strong> $
                      {Number(currentOrder?.fitting_total).toFixed(2)}
                    </div>
                  ) : null}
                  {currentOrder?.tax_total && currentOrder?.tax_total > 0 ? (
                    <>
                      <div className="couponcode">
                        <strong>Total before Tax :</strong> $
                        {totalBeforeTax(couponCode, Number(productDetail.fittingTotalAmount ?? 0), Number(productDetail.productTotalAmount ?? 0), Number(couponDiscount ?? 0))}
                      </div>
                      <div className="couponcode">
                        <strong>Sales Tax :</strong> $
                        {Number(currentOrder?.tax_total).toFixed(2)}
                      </div>
                    </>
                  ) : null}
                  {variationData?.serviceTotalPrice > 0 && <div className="couponcode">
                    <strong>Service/Labour :</strong> $
                    {Number(variationData?.serviceTotalPrice).toFixed(2)}
                  </div>
                  }
                  {currentOrder?.shipping_mode.totalCharges !== 0 ? (
                    <div className="couponcode">
                      <strong>Shipping :</strong> $
                      {Number(currentOrder?.shipping_mode.totalCharges).toFixed(
                        2
                      )}
                    </div>
                  ) : null}

                  <div className="couponcode">
                    <strong>Grand Total : </strong>$
                    {Number(currentOrder?.grand_total?.$numberDecimal).toFixed(
                      2
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          ) : null}
        </div>
      </CardBody>
    </Card>
  );
};

CalculationDetail.propTypes = {
  currentOrder: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  variationData: state.order.variationData,
  currentOrder: state.order.currentOrder,
  coupon_loading: state.order.coupon_loading,
});

export default connect(mapStateToProps, {
  selectedVariations,
})(CalculationDetail);
