/* eslint-disable */
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import {
  edit,
  getDealerById,
  getDealerTypes,
  editPassword,
  dealerApproveDisapprove,
} from 'actions/admin/dealer';
import Spinner from 'views/Spinner';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Form,
  FormText,
  FormGroup,
  Input,
  Label,
  Row,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  //InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import Errors from 'views/Notifications/Errors';
import { REMOVE_ERRORS, SET_GOOGLE_ADDRESS_FORM_DATA } from 'actions/types';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
import { setGoogleAddressFormData } from 'actions/commonActions';
import 'react-phone-number-input/style.css';
import ReactTooltip from 'react-tooltip';
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import extractNumber from 'utils/extractNumber';
import { getCountryShort } from 'utils/countryCode';
import { FaTiktok } from 'react-icons/fa';

const EditDealer = ({
  getDealerById,
  getDealerTypes,
  edit,
  editPassword,
  dealer: { currentUser, loading, dealerTypeList },
  history,
  match,
  errorList,
  dealerApproveDisapprove,
  googleAddressFormData,
  setGoogleAddressFormData,
}) => {
  /*************************form data********************/
  let navigate = useNavigate();
  const inputRef = useRef(null);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    business_name: '',
    business_email: '',
    ccode: '+1',
    contact_no: '',
    iute164_phone: '',
    website: '',
    business_license_no: '',
    business_model_description: '',
    annual_sales_volume: '',
    shaft_clubhead_component_vendors: '',
    dealer_type_id: '',
    image: '',
    selectedFile: '',
    oldImage: '',
    isNewFileSelected: 0,
    address: '',
    latitute: 0,
    longitude: 0,
    address_line_1: '',
    address_line_2: '',
    google_map_link: '',
    fitting_studio: false,
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: '',
    facebook: '',
    instagram: '',
    // linkedIn: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    tiktok: '',
    user_address_id: null,
    dealer_status: '',
  });

  /*************************explode form data********************/
  const {
    first_name,
    last_name,
    email,
    dealer_type_id,
    business_name,
    business_email,
    ccode,
    contact_no,
    iute164_phone,
    website,
    business_license_no,
    business_model_description,
    annual_sales_volume,
    shaft_clubhead_component_vendors,
    selectedFile,
    image,
    address,
    latitute,
    longitude,
    address_line_1,
    address_line_2,
    google_map_link,
    fitting_studio,
    city,
    zipcode,
    country,
    state,
    facebook,
    instagram,
    // linkedIn,
    twitter,
    youtube,
    snapchat,
    tiktok,
    user_address_id,
    dealer_status,
  } = formData;

  /*************************get dealer type data********************/
  useMemo(() => getDealerTypes(), [getDealerTypes]);

  /*************************get dealer details********************/
  const { user_id: user_id } = useParams();
  const [autocompleteValue, setAutocompleteValue] = useState('');
  useMemo(() => {
    getDealerById(user_id, navigate);
  }, [user_id]);

  /*************************set form data********************/
  useMemo(() => {
    if (Object.keys(currentUser).length > 0) {
      let var_ccode =
        currentUser &&
          currentUser?.dealer_info &&
          currentUser?.dealer_info?.ccode
          ? currentUser?.dealer_info?.ccode
          : '+1';
      let var_contact_no =
        currentUser &&
          currentUser?.dealer_info &&
          currentUser?.dealer_info?.contact_no
          ? currentUser?.dealer_info?.contact_no
          : '';
      let var_iute164_phone = var_ccode + var_contact_no;

      setFormData({
        first_name:
          currentUser && currentUser?.first_name ? currentUser?.first_name : '',
        last_name:
          currentUser && currentUser?.last_name ? currentUser?.last_name : '',
        email: currentUser && currentUser?.email ? currentUser?.email : '',
        dealer_type_id:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.dealer_type_id
            ? currentUser?.dealer_info?.dealer_type_id
            : '',
        business_name:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.business_name
            ? currentUser?.dealer_info?.business_name
            : '',
        business_email:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.business_email
            ? currentUser?.dealer_info?.business_email
            : '',
        contact_no: var_contact_no,
        ccode: var_ccode,
        iute164_phone: var_iute164_phone,
        website:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.website
            ? currentUser?.dealer_info?.website
            : '',
        business_license_no:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.business_license_no
            ? currentUser?.dealer_info?.business_license_no
            : '',
        business_model_description:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.business_model_description
            ? currentUser?.dealer_info?.business_model_description
            : '',
        annual_sales_volume:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.annual_sales_volume
            ? currentUser?.dealer_info?.annual_sales_volume
            : '',
        shaft_clubhead_component_vendors:
          currentUser &&
            currentUser?.dealer_info &&
            currentUser?.dealer_info?.shaft_clubhead_component_vendors
            ? currentUser?.dealer_info?.shaft_clubhead_component_vendors
            : '',
        image: !currentUser?.avatar
          ? ''
          : //process.env.REACT_APP_AWS_STORAGE_URL +
          process.env.REACT_APP_AWS_STORAGE_URL +
          '/uploads/dealer-logo/' +
          currentUser?.avatar,
        oldImage: !currentUser?.avatar ? '' : currentUser?.avatar,
        isNewFileSelected: 0,
        address: !currentUser?.dealer_address?.address
          ? ''
          : currentUser?.dealer_address?.address,
        address_line_1: !currentUser?.dealer_address?.address_line_1
          ? ''
          : currentUser?.dealer_address?.address_line_1,
        address_line_2: !currentUser?.dealer_address?.address_line_2
          ? ''
          : currentUser?.dealer_address?.address_line_2,
        fitting_studio: !currentUser?.dealer_address?.fitting_studio
          ? false
          : currentUser?.dealer_address?.fitting_studio,
        google_map_link: !currentUser?.dealer_address?.google_map_link
          ? ''
          : currentUser?.dealer_address?.google_map_link,
        latitute: !currentUser?.dealer_address?.latitute
          ? ''
          : currentUser?.dealer_address?.latitute,
        longitude: !currentUser?.dealer_address?.longitude
          ? ''
          : currentUser?.dealer_address?.longitude,
        state: !currentUser?.dealer_address?.state
          ? ''
          : currentUser?.dealer_address?.state,
        country: !currentUser?.dealer_address?.country
          ? ''
          : currentUser?.dealer_address?.country,
        country_code: !currentUser?.dealer_address?.country_code
          ? ''
          : currentUser?.dealer_address?.country_code,
        city: !currentUser?.dealer_address?.city
          ? ''
          : currentUser?.dealer_address?.city,
        zipcode: !currentUser?.dealer_address?.zipcode
          ? ''
          : currentUser?.dealer_address?.zipcode,
        google_id: !currentUser?.dealer_address?.google_id
          ? ''
          : currentUser?.dealer_address?.google_id,
        google_place_id: !currentUser?.dealer_address?.google_place_id
          ? ''
          : currentUser?.dealer_address?.google_place_id,
        facebook:
          currentUser && currentUser?.dealer_info?.facebook
            ? currentUser?.dealer_info?.facebook
            : '',
        instagram:
          currentUser && currentUser?.dealer_info?.instagram
            ? currentUser?.dealer_info?.instagram
            : '',
        twitter:
          currentUser && currentUser?.dealer_info?.twitter
            ? currentUser?.dealer_info?.twitter
            : '',
        youtube:
          currentUser && currentUser?.dealer_info?.youtube
            ? currentUser?.dealer_info?.youtube
            : '',
        snapchat:
          currentUser && currentUser?.dealer_info?.snapchat
            ? currentUser?.dealer_info?.snapchat
            : '',
        tiktok:
          currentUser && currentUser?.dealer_info?.tiktok
            ? currentUser?.dealer_info?.tiktok
            : '',
        user_address_id:
          currentUser && currentUser?.dealer_address?.address_id
            ? currentUser?.dealer_address?.address_id
            : '',
        dealer_status: currentUser && currentUser?.status,
      });
    }
  }, [currentUser]);

  /*************************use dispatch********************/
  const dispatch = useDispatch();

  /*************************on change event********************/
  const onChange = (e) => {
    if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];
      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
        isNewFileSelected: 1,
      });
    } else if (e.target.name === 'business_license_no') {
      setFormData({ ...formData, business_license_no: e.target.value.replace(/[&#<>@]/g, '') })
    } else {
      // setFormData({ ...formData, [e.target.name]: e.target.value });
      const newFormData = { ...formData, [e.target.name]: e.target.value };
      newFormData['address'] = `${newFormData.address_line_1} ${newFormData.address_line_2 ? newFormData.address_line_2 + ',' : ''
        } ${newFormData.city} ${newFormData.state} ${newFormData.zipcode} ${newFormData.country
        }`;
      setFormData(newFormData);
    }
    dispatch({ type: REMOVE_ERRORS });
  };

  const onFittingStudioChange = (e) => {
    setFormData({ ...formData, fitting_studio: e.target.checked });
  };

  //########################## change event for phone number ##############################
  const onPhoneChange = (number) => {
    let var_contact_no = extractNumber(number, ccode);
    setFormData((form) => ({
      ...form,
      contact_no: var_contact_no,
      iute164_phone: number ?? '',
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      ccode: '+' + getCountryCallingCode(code ?? getCountryShort(ccode)),
    }));
  };

  //#################### update formdata after changes in google address  #################
  useEffect(() => {
    if (Object.keys(googleAddressFormData).length > 0) {
      setFormData({
        ...formData,
        // ...googleAddressFormData,
        first_name: googleAddressFormData.first_name,
        last_name: googleAddressFormData.last_name,
        email: googleAddressFormData.email,
        business_name: googleAddressFormData.business_name,
        business_email: googleAddressFormData.business_email,
        password: googleAddressFormData.password,
        confirm_password: googleAddressFormData.confirm_password,
        contact_no: googleAddressFormData.contact_no,
        ccode: googleAddressFormData.ccode,
        // iute164_phone: googleAddressFormData.iute164_phone,
        selectedFile: googleAddressFormData.selectedFile,
        image: googleAddressFormData.image,
        website: googleAddressFormData.website,
        business_license_no: googleAddressFormData.business_license_no,
        business_model_description:
          googleAddressFormData.business_model_description,
        annual_sales_volume: googleAddressFormData.annual_sales_volume,
        shaft_clubhead_component_vendors:
          googleAddressFormData.shaft_clubhead_component_vendors,
        user_address_id: googleAddressFormData.user_address_id,
        dealer_status: googleAddressFormData.dealer_status,
        dealer_type_id: googleAddressFormData.dealer_type_id,
        facebook: googleAddressFormData.facebook,
        instagram: googleAddressFormData.instagram,
        // linkedIn,
        twitter: googleAddressFormData.twitter,
        youtube: googleAddressFormData.youtube,
        snapchat: googleAddressFormData.snapchat,
        tiktok: googleAddressFormData.tiktok,
      });
    }
    dispatch({
      type: SET_GOOGLE_ADDRESS_FORM_DATA,
      payload: {},
    });
  }, [address]);

  const setGMapPlace = useCallback((location) => {
    const google_id = location.id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let latitute = location?.geometry?.location?.lat((e) => e.Scopes[0].e);
    let longitude = location?.geometry?.location?.lng((e) => e.Scopes[0].f);

    let city = '';
    let state = '';
    let address_line_1 = '';
    let address_line_2 = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? ' ' : '') + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? ' ' : '') + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case 'administrative_area_level_2':
          case 'postal_town':
            if (city == '') {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      });
    setFormData((fData) => ({
      ...fData,
      address,
      latitute,
      longitude,
      city,
      address_line_1,
      address_line_2,
      google_id,
      google_place_id,
      state,
      country,
      country_code,
      zipcode,
      // location: geometry,
    }));
    setAutocompleteValue('');
  }, []);
  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  /*************************on submit event********************/
  const onSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;

    // if (form.checkValidity() === false) {
    //   setValidated(true);
    //   return false;
    // }

    // if (form.checkValidity()) {
    setValidated(false);
    setFormData({
      ...formData,
    });
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    if (dealer_status === 2) {
      if (
        window.confirm(
          `Are you sure, you want to update dealer information?`
        )
      ) {
        edit(formData, navigate, user_id);
      }
    } else {
      if (
        window.confirm(
          `Are you sure, you want to approve dealer registration and update dealer information?`
        )
      ) {
        setFormData({ ...formData, dealer_status: 2 });
        formData['dealer_status'] = 2;
        edit(formData, navigate, user_id);
      }
      // }
    }
  };

  /*************************password form initiall********************/
  const initialPasswordFormData = {
    password: '',
    confirm_password: '',
  };

  /*************************set password form data********************/
  const [passwordFormData, setPasswordFormData] = useState(
    initialPasswordFormData
  );

  /*************************explode form data********************/
  const { password, confirm_password } = passwordFormData;
  const [checkPassword, setCheckPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  /*************************modal show and hide********************/
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal)
    setPasswordShown(false)
    setConfirmPassword(false)
  };

  /*************************reset modal form data********************/
  const reset = () => {
    dispatch({ type: REMOVE_ERRORS });
    setPasswordFormData(initialPasswordFormData);
  };

  /*************************on change password form data********************/
  const onPasswordChange = (e) => {
    setPasswordFormData({
      ...passwordFormData,
      [e.target.name]: e.target.value?.trim(),
    });
    setCheckPassword(false);
    setErrorMessage('');
  };

  const togglePassword = (e) => {
    e.preventDefault();
    if (e.currentTarget.getAttribute("data-id") == "password") {
      setPasswordShown(!passwordShown);
      setConfirmPassword(confirmPassword);
    }
    if (e.currentTarget.getAttribute("data-id") == "confirm_password") {
      setPasswordShown(passwordShown);
      setConfirmPassword(!confirmPassword);
    }
  };

  /*************************on submit password form data********************/
  const onModelSubmit = (e) => {
    e.preventDefault();
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    const isChecked = regex.test(password);
    if (password !== confirm_password) {
      setErrorMessage("Password do not match.");
    } else if (!isChecked) {
      setCheckPassword(true);
      setErrorMessage('')
    } else {
      setErrorMessage('');
      setCheckPassword(false);
      editPassword(passwordFormData, user_id).then((res) => {
        if (res) setModal(!modal);
      });
    }
  };

  //remove file
  var removeFile = () => () => {
    document.getElementById('thumbnail').value = '';
    setFormData({
      ...formData,
      image: '',
      selectedFile: '',
      oldImage: '',
      isNewFileSelected: 0,
    });
  };

  return loading ? (
    <Spinner />
  ) : (
    <div className="animated fadeIn">
      <Form
        // noValidate
        // validated={validated}
        className="form-horizontal"
        onSubmit={(e) => onSubmit(e)}
      >
        <Row>
          <Col xs="12" md="12">
            <Card>
              <CardHeader className="staff-info">
                <h5>Owner Information</h5>
                <Button size="sm" color="primary" onClick={toggle}>
                  <i className="fa fa-dot-circle-o"></i> Change Password
                </Button>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="first_name">
                        First Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="first_name"
                        name="first_name"
                        maxLength="25"
                        value={first_name}
                        onChange={(e) => onChange(e)}
                        invalid={first_name == '' && validated ? true : false}
                      />
                      <Errors current_key="first_name" key="first_name" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="last_name">Last Name</Label>
                      <Input
                        type="text"
                        id="last_name"
                        name="last_name"
                        maxLength="25"
                        value={last_name}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.last_name ? true : false}
                      />
                      <Errors current_key="last_name" key="last_name" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="email">
                        Email <span>*</span>
                      </Label>
                      <Input
                        type="email"
                        id="email"
                        name="email"
                        maxLength="50"
                        value={email}
                        onChange={(e) => onChange(e)}
                        invalid={email == '' && validated ? true : false}
                      />
                      <Errors current_key="email" key="email" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="dealer_type_id">
                        Dealer Type <span>*</span>
                      </Label>
                      <Input
                        type="select"
                        name="dealer_type_id"
                        id="dealer_type_id"
                        value={dealer_type_id}
                        onChange={(e) => onChange(e)}
                        // required
                        invalid={
                          dealer_type_id == '' && validated ? true : false
                        }
                      >
                        <option value={''} disabled>
                          {' '}
                          {'Select'}{' '}
                        </option>
                        {dealerTypeList
                          ? dealerTypeList.map((fieldData, index) => {
                            return (
                              <option key={index} value={fieldData._id}>
                                {fieldData.name}
                              </option>
                            );
                          })
                          : ''}
                      </Input>
                      <Errors
                        current_key="dealer_type_id"
                        key="dealer_type_id"
                      />
                      { }
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h5>Business Information</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="business_name">
                        Business Name <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="business_name"
                        name="business_name"
                        maxLength="100"
                        value={business_name}
                        onChange={(e) => onChange(e)}
                        invalid={
                          business_name == '' && validated ? true : false
                        }
                      />
                      <Errors current_key="business_name" key="business_name" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="business_email">
                        Business Email <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="business_email"
                        name="business_email"
                        maxLength="100"
                        value={business_email}
                        onChange={(e) => onChange(e)}
                        invalid={
                          business_email == '' && validated ? true : false
                        }
                      />
                      <Errors
                        current_key="business_email"
                        key="business_email"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="address">Search Your Address</Label>
                      <Autocomplete
                        apiKey={GOOGLE_MAPS_API_KEY}
                        onPlaceSelected={(place) => {
                          {
                            setGMapPlace(place);
                          }
                        }}
                        options={{
                          types: ['address'],
                        }}
                        value={autocompleteValue}
                        onChange={handleInputChange}
                        className="form-control"
                        onFocus={() => setGoogleAddressFormData(formData)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="address">
                        Address Line 1<span>*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="address_line_1"
                        name="address_line_1"
                        placeholder="Address Line 1 *"
                        maxLength="100"
                        value={address_line_1}
                        onChange={(e) => onChange(e)}
                        invalid={
                          address_line_1 == '' && validated ? true : false
                        }
                      />
                      <Errors
                        current_key="address_line_1"
                        key="address_line_1"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="address">Address Line 2</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="address_line_2"
                        name="address_line_2"
                        placeholder="Address Line 2"
                        maxLength="100"
                        value={address_line_2}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.address_line_2 ? true : false}
                      />
                      <Errors
                        current_key="address_line_2"
                        key="address_line_2"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="country">
                        Country <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        name="country"
                        id="country"
                        value={country}
                        invalid={country == '' && validated ? true : false}
                      ></Input>
                      <Errors current_key="country" key="country" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="state">State <span>*</span></Label>
                      <Input
                        type="text"
                        name="state"
                        id="state"
                        value={state}
                        invalid={errorList.state ? true : false}
                      />
                      <Errors current_key="state" key="state" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="city">
                        City <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="city"
                        name="city"
                        maxLength="100"
                        value={city}
                        invalid={city == '' && validated ? true : false}
                      />
                      <Errors current_key="city" key="city" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="zipcode">
                        Zip/Postal Code <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="zipcode"
                        name="zipcode"
                        maxLength="10"
                        // minLength="3"
                        value={zipcode}
                        onChange={(e) => onChange(e)}
                        invalid={zipcode == '' && validated ? true : false}
                      />
                      <Errors current_key="zipcode" key="zipcode" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="contact_no">
                        Contact Number <span>*</span>
                      </Label>
                      {/* {iute164_phone && ( */}
                      <PhoneInput
                        className=""
                        autoComplete="tel"
                        withCountryCallingCode={true}
                        countryCallingCodeEditable={false}
                        smartCaret={true}
                        international={true}
                        defaultCountry="US"
                        value={iute164_phone}
                        // countrySelectProps={{ unicodeFlags: true }}
                        name="contact_no"
                        addInternationalOption={false}
                        onChange={onPhoneChange}
                        onCountryChange={onCountryChange}
                      />
                      {/* )} */}
                      <Errors current_key="contact_no" key="contact_no" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="google_map_link">Google Map Link</Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="google_map_link"
                        name="google_map_link"
                        value={google_map_link}
                        onChange={(e) => onChange(e)}
                        // disabled={true}
                        invalid={errorList.google_map_link ? true : false}
                      />
                      <Errors
                        current_key="google_map_link"
                        key="google_map_link"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup className="checkouter m-0">
                      <Label className="custom_check">
                        Fitting Studio
                        <Input
                          type="checkbox"
                          // style={{ transform: "scale(1.5)", marginLeft: "5px" }}
                          id="fitting_studio"
                          name="fitting_studio"
                          className="mr-2"
                          checked={fitting_studio}
                          onChange={onFittingStudioChange}
                          // disabled={true}
                          invalid={errorList.fitting_studio ? true : false}
                        />
                        <span className="check_indicator">&nbsp;</span>
                      </Label>
                      <Errors
                        current_key="fitting_studio"
                        key="fitting_studio"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label>Social media handles</Label>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup size="lg">
                        <Input
                          placeholder="Facebook Link"
                          id="facebook"
                          name="facebook"
                          value={facebook}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.facebook ? true : false}
                        />

                        <InputGroupText>
                          <i className="fa fa-facebook"></i>
                        </InputGroupText>

                        <Errors current_key="facebook" key="facebook" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup size="lg">
                        <Input
                          placeholder="Instagram Link"
                          id="instagram"
                          name="instagram"
                          value={instagram}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.instagram ? true : false}
                        />

                        <InputGroupText>
                          <i className="fa fa-instagram"></i>
                        </InputGroupText>

                        <Errors current_key="instagram" key="instagram" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup size="lg">
                        <Input
                          placeholder="Twitter Link"
                          id="twitter"
                          name="twitter"
                          value={twitter}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.twitter ? true : false}
                        />

                        <InputGroupText>
                          <i className="fa fa-twitter"></i>
                        </InputGroupText>

                        <Errors current_key="twitter" key="twitter" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup size="lg">
                        <Input
                          placeholder="YouTube Link"
                          id="youtube"
                          name="youtube"
                          value={youtube}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.youtube ? true : false}
                        />

                        <InputGroupText>
                          <i className="fa fa-youtube"></i>
                        </InputGroupText>

                        <Errors current_key="youtube" key="youtube" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup size="lg">
                        <Input
                          placeholder="Snapchat Link"
                          id="snapchat"
                          name="snapchat"
                          value={snapchat}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.snapchat ? true : false}
                        />

                        <InputGroupText>
                          <i className="fa fa-snapchat"></i>
                        </InputGroupText>

                        <Errors current_key="snapchat" key="snapchat" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <InputGroup size="lg">
                        <Input
                          placeholder="Tiktok Link"
                          id="tiktok"
                          name="tiktok"
                          value={tiktok}
                          onChange={(e) => onChange(e)}
                          invalid={errorList.tiktok ? true : false}
                        />

                        <InputGroupText>
                          <FaTiktok />
                        </InputGroupText>

                        <Errors current_key="tiktok" key="tiktok" />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="thumbnail">Upload Logo</Label>
                      <Input
                        className="form-control"
                        type="file"
                        id="thumbnail"
                        name="thumbnail"
                        onChange={(e) => onChange(e)}
                        // multiple
                        invalid={errorList.image ? true : false}
                      />
                      {image && (
                        <div>
                          <img src={image} className="preview-img" alt="" />
                          <Button
                            title="Remove Image"
                            className="btn btn-sm btn-danger"
                            onClick={removeFile()}
                          >
                            <i className="fa fa-close"></i>
                          </Button>
                        </div>
                      )}
                      <Errors current_key="image" key="image" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="website">Website</Label>
                      <Input
                        type="text"
                        id="website"
                        name="website"
                        maxLength="100"
                        value={website}
                        onChange={(e) => onChange(e)}
                        invalid={errorList.website ? true : false}
                      />
                      <Errors current_key="website" key="website" />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="business_license_no">
                        Business License # or Tax I.D. Number <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="business_license_no"
                        name="business_license_no"
                        maxLength="100"
                        value={business_license_no}
                        onChange={(e) => onChange(e)}
                        invalid={
                          business_license_no == '' && validated ? true : false
                        }
                      />
                      <Errors
                        current_key="business_license_no"
                        key="business_license_no"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                      <Label htmlFor="business_model_description">
                        Description of your business model/plan <span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        id="business_model_description"
                        name="business_model_description"
                        maxLength="100"
                        value={business_model_description}
                        onChange={(e) => onChange(e)}
                        invalid={
                          business_model_description == '' && validated
                            ? true
                            : false
                        }
                      />
                      <Errors
                        current_key="business_model_description"
                        key="business_model_description"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="annual_sales_volume">
                        Annual sales volume of metalwoods built or retrofitted
                        with premium shafts? <span>*</span>
                      </Label>
                      <Input
                        type="number"
                        id="annual_sales_volume"
                        name="annual_sales_volume"
                        min={0}
                        // maxLength="100"
                        value={annual_sales_volume}
                        onChange={(e) => onChange(e)}
                        invalid={
                          annual_sales_volume == '' && validated ? true : false
                        }
                      />
                      <Errors
                        current_key="annual_sales_volume"
                        key="annual_sales_volume"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <Label htmlFor="shaft_clubhead_component_vendors">
                        Primary component suppliers <span>*</span>
                      </Label>
                      <Input
                        type="text"
                        id="shaft_clubhead_component_vendors"
                        name="shaft_clubhead_component_vendors"
                        maxLength="100"
                        value={shaft_clubhead_component_vendors}
                        onChange={(e) => onChange(e)}
                        invalid={
                          shaft_clubhead_component_vendors == '' && validated
                            ? true
                            : false
                        }
                      />
                      <Errors
                        current_key="shaft_clubhead_component_vendors"
                        key="shaft_clubhead_component_vendors"
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardFooter>
                {dealer_status === 0 ? (
                  <>
                    <Button
                      className="mr-05"
                      type="submit"
                      size="sm"
                      color="primary"
                    >
                      <i className="fa fa-dot-circle-o"></i> Approve
                    </Button>
                    <Button
                      className="mr-05"
                      type="button"
                      size="sm"
                      color="danger"
                      onClick={(e) => {
                        if (
                          window.confirm(
                            `Are you sure, you want to  reject dealer registration (Information will not be updated)?`
                          )
                        ) {
                          dealerApproveDisapprove(user_id, 3).then((res) => {
                            if (res) navigate('/admin/dealers');
                          });
                        }
                      }}
                    >
                      <i className="fa fa-dot-circle-o"></i> Reject
                    </Button>
                  </>
                ) : dealer_status === 1 || dealer_status === 3 ? (
                  <Button
                    className="mr-05"
                    type="submit"
                    size="sm"
                    color="primary"
                  >
                    <i className="fa fa-dot-circle-o"></i> Approve
                  </Button>
                ) : (
                  dealer_status === 2 && (
                    <>
                      <Button
                        className="mr-05"
                        type="submit"
                        size="sm"
                        color="primary"
                        disabled={loading}
                      >
                        <i className="fa fa-dot-circle-o"></i> Submit
                      </Button>
                      <Button
                        className="mr-05"
                        type="button"
                        size="sm"
                        color="danger"
                        onClick={(e) => {
                          if (
                            window.confirm(
                              `Are you sure, you want to  reject dealer registration (Information will not be updated)?`
                            )
                          ) {
                            dealerApproveDisapprove(user_id, 3).then((res) => {
                              if (res) navigate('/admin/dealers');
                            });
                          }
                        }}
                      >
                        <i className="fa fa-dot-circle-o"></i> Reject
                      </Button>
                    </>
                  )
                )}
                <Link to="/admin/dealers">
                  <Button
                    className="mr-05"
                    type="reset"
                    size="sm"
                    color="danger"
                  >
                    <i className="fa fa-ban"></i> Cancel
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Form>

      <Modal isOpen={modal} toggle={toggle} onClosed={reset}>
        <ModalHeader toggle={toggle}>Change Password</ModalHeader>
        <Form className="form-horizontal" onSubmit={(e) => onModelSubmit(e)}>
          <ModalBody>
            <div className="mb-3">
              <InputGroup>
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
                {password == '' &&
                  <ReactTooltip id="title required1" efect="solid" place="bottom">
                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                  </ReactTooltip>
                }
                <Input
                  type={(passwordShown) ? "text" : "password"}
                  placeholder="Password"
                  autoComplete="password"
                  ref={inputRef}
                  data-tip
                  data-for="title required1"
                  name="password"
                  id="password"
                  value={password}
                  onChange={(e) => onPasswordChange(e)}
                  invalid={errorList.password ? true : false}
                />
                <InputGroupText onClick={togglePassword} data-id="password" >{(passwordShown) ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroupText>
              </InputGroup>
              <Errors current_key="password" key="password" />
              {(checkPassword && password != "") &&
                <div id="message">
                  <FormText style={{ fontSize: "10px" }} className='text-danger'> Password requirements:</FormText>
                  <FormText>
                    <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                    <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                  </FormText>
                </div>
              }
            </div>
            <div className="mb-3">
              <InputGroup >
                <InputGroupText>
                  <i className="icon-lock"></i>
                </InputGroupText>
                {confirm_password == '' &&
                  <ReactTooltip id="title required2" efect="solid" place="bottom">
                    Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                  </ReactTooltip>
                }
                <Input
                  type={(confirmPassword) ? "text" : "password"}
                  placeholder="Confirm Password"
                  autoComplete="confirm_password"
                  name="confirm_password"
                  id="confirm_password"
                  ref={inputRef}
                  data-tip
                  data-for="title required2"
                  value={confirm_password}
                  onChange={(e) => onPasswordChange(e)}
                  invalid={errorList.confirm_password ? true : false}
                />
                <InputGroupText onClick={togglePassword} data-id="confirm_password" >{(confirmPassword) ? <FaRegEyeSlash /> : <FaRegEye />}</InputGroupText>
              </InputGroup>
              <Errors current_key="confirm_password" key="confirm_password" />
              {errorMessage && <span className='text-danger'>{errorMessage}</span>}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button size="sm" color="primary" type="submit">
              <i className="fa fa-dot-circle-o"></i> Submit
            </Button>
            <Button type="reset" size="sm" color="danger" onClick={toggle}>
              <i className="fa fa-ban"></i> Cancel
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

EditDealer.propTypes = {
  getDealerById: PropTypes.func.isRequired,
  getDealerTypes: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  dealer: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  editPassword: PropTypes.func.isRequired,
  dealerApproveDisapprove: PropTypes.func.isRequired,
  setGoogleAddressFormData: PropTypes.func.isRequired,
  googleAddressFormData: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  dealer: state.dealer,
  errorList: state.errors,
  googleAddressFormData: state.commonActions.googleAddressFormData,
});

export default connect(mapStateToProps, {
  getDealerById,
  getDealerTypes,
  edit,
  editPassword,
  dealerApproveDisapprove,
  setGoogleAddressFormData,
})(EditDealer);
