import { connect } from 'react-redux';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import {
    selectedVariations
} from 'actions/admin/order';
import { useEffect, useState } from 'react';
import { Row, Col, Input, Label, FormGroup } from 'reactstrap';
import Select from 'react-select';

const CustomizeShaft = ({
    customizationProductField,
    currentProduct,
    variationData,
    selectedVariations,
}) => {
    const extraOptions = ['extra_wraps', 'grip_logo']
    const [fittingList, setFittingList] = useState({});

    const getFittings = (title, isOption = false) => {
        const fitting = currentProduct?.fittingData?.find((ftt) =>
            ftt.title.toLowerCase().includes(title)
        )?.fittings?.filter(ft => ft.categoryIds.includes(currentProduct.categoryId._id) && ft.status !== 0 && ft?.isDeleted !== true);
        if (isOption && fitting) {
            if (fittingList[title]) {

                return fitting.find((ft) => ft._id === fittingList[title]['value'])?.options?.map((fts) => {
                    return { label: fts.title, value: fts._id }
                });
            } else if (variationData?.selectedFittingsData && variationData.selectedFittingsData[title]) {
                return fitting.find((ft) => ft._id === variationData?.selectedFittingsData[title]['value'])
                    ?.options?.map((fts) => {
                        return { label: fts.title, value: fts._id }
                    });
            }
            return [];
        }
        const showPrice = title === "adaptor" && isOption === false
        return fitting?.map((fts) => {
            return { label: showPrice ? `${fts.title} / $${fts.price}` : fts.title, value: fts._id }
        }) ?? [];
    }

    const onFittingChange = (id, value) => {
        if (id == "adaptor") {
            setFittingList((st) => ({ ...st, adaptor_option: '' }));
            variationData.selectedFitting['adaptor_option'] = "";
            variationData.selectedFittingsData['adaptor_option'] = "";
        }
        if (id == 'grip') {
            setFittingList((st) => ({ ...st, grip_option: '' }));
            variationData.selectedFitting['grip_option'] = "";
            variationData.selectedFittingsData['grip_option'] = "";
        }
        variationData.selectedFitting[id] = value;

        setFittingList((st) => ({ ...st, [id]: value }));
        selectedVariations(variationData)
    };

    return (
        <>
            {customizationProductField.map((item, index) => (<Col md="12" key={index}>
                <Row>
                    {item.map((row, key) => (
                        <Col sm="6" key={row.name}>
                            <FormGroup>
                                <Label for={row.name} >
                                    {row.labelName}{"*"}
                                </Label>
                                <Select
                                    value={[variationData.selectedFitting[row.changeOptions]]}
                                    isClearable={false}
                                    name={row.name}
                                    options={extraOptions.includes(row.type) ? row.options : getFittings(row.type, row.isOption)}
                                    onChange={(e) => onFittingChange(row.changeOptions, e)}
                                    placeholder={`${row.placeholder}*`}
                                    keepSelectedInList={false}

                                />
                            </FormGroup>
                        </Col>
                    ))}
                </Row>
            </Col>
            ))}
            <Col md="12" >
                <Row>
                    <Col md="12">
                        <div className="form-group">
                            <label>Product Customization Notes</label>
                            <Input
                                type="textarea"
                                minLength={0}
                                maxLength={255}
                                name="product_customization"
                                defaultValue={
                                    variationData?.selectedFittingsData
                                        ? variationData.selectedFittingsData.product_customization
                                        : ''
                                }
                                onChange={(e) => {
                                    onFittingChange(
                                        'product_customization',
                                        e.target.value
                                    );
                                }}
                            ></Input>
                        </div>
                    </Col>
                </Row>
            </Col>
        </>
    )
}
const mapStateToProps = (state) => ({
    variationData: state.order.variationData,
});

export default connect(mapStateToProps, {
    selectedVariations
})(CustomizeShaft);